<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2"
					>Fornecedores</v-toolbar-title
				>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
				<v-btn class="ml-2 my-1" color="primary" @click="exportar()">
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
				</v-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					@click="exportarImportacoes()"
				>
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar<br />
					Importações e Edições
				</v-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					:disabled="mes < mesProximo"
					@click="abrirImportar()"
				>
					<v-icon left>mdi-upload</v-icon> Importar
				</v-btn>
			</v-col>
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1">
			<v-row no-gutters class="mb-2">
				<v-col cols="12" sm="12" md="4" lg="4" xl="4">
					<v-text-field
						v-model="busca"
						append-icon="mdi-magnify"
						label="Pesquisar"
						dense
						outlined
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3">
					<v-form ref="formFiltro">
						<v-text-field
							label="Mês"
							v-model="mes"
							:rules="regra.mes"
							required
							outlined
							dense
							type="month"
						></v-text-field>
					</v-form>
				</v-col>
				<v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
					<v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dados"
				item-key="id_fornecedor"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.botao`]="{ item }">
					<v-icon
						
						small
						class="mr-2"
						color="primary"
						@click="enviarGuias(item)"
						title="Guias enviados"
					>
						mdi-checkbook-arrow-right
					</v-icon>
					<v-icon
						v-if="item.mes >= mesProximo"
						small
						class="mr-2"
						color="primary"
						@click="abrirEditar(item)"
						title="Editar"
					>
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
		</v-card>
		<v-dialog v-model="dialogoEnvioGuias" width="1000">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text fix-flex">
					Controle de envio de guias
					<v-spacer></v-spacer>
					<v-btn
							fab
							width="24px"
							height="24px" 
							elevation="0"
							light
							@click="dialogoEnvioGuias = false"
					>
							<v-icon size="18px" color="primary">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="mt-4">
					<controle-envio-guia :fornecedor="fornecedor"/>
				</v-card-text>		
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogo" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Editar Fornecedor
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form ref="formEditar" v-model="validaForm">
						<v-row no-gutters class="mt-4">
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Código RD"
									v-model="fornecedor.codigo"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="ID Fornecedores"
									v-model="fornecedor.id_fornecedor"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Fornecedores"
									v-model="fornecedor.nome"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="CNPJ"
									v-model="fornecedor.cnpj_matriz"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Razão Social"
									v-model="fornecedor.razao_social"
								></v-text-field>
							</v-col>
							
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Mês"
									v-model="fornecedor.mes_nome"
								></v-text-field>
							</v-col>
							<v-col cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6" class="px-2">
								<v-select
									label="Negociação Comercial"
									
									outlined
									dense
									v-model="fornecedor.negociacao_comercial"
									:items="['SIM','NÃO']"
									:rules="regra.negociacao_comercial"
									required
								></v-select>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-select
									label="Ignora Acordo"
									:items="lista.ignora_acordo"
									:rules="regra.ignora_acordo"
									item-text="nome"
									item-value="ignora_acordo"
									v-model="fornecedor.ignora_acordo"
									outlined
									dense
								>
								</v-select>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									label="Desconto (%)"
									v-model="fornecedor.desconto"
									:rules="regra.desconto"
									v-mask="'###'"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-select
									label="Macro Fornecedor"
									:items="lista.macrofornecedor"
									item-text="nome"
									item-value="id_macrofornecedor"
									v-model="fornecedor.id_macrofornecedor"
									clearable
									outlined
									dense
								>
								</v-select>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogo = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
					<v-btn
						color="primary"
						class="pr-4"
						
						@click="editar()"
					>
						<v-icon left>mdi-content-save</v-icon> Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV para
					{{ $moment(mes).format("MMMM [de] YYYY") }}
				</v-card-title>
				<v-card-text>
					<v-alert
						class="mt-4"
						text
						type="info"
						border="left"
						style="font-size: 12px;"
					>
						<ul>
							<li>
								A base utilizada para importação deve ser
								parecida com base exportada. As colunas
								<strong>codigo_rd</strong> (Código RD) e
								<strong>desconto</strong> (Desconto) são
								obrigatórias (<strong
									>e os nomes devem ser identicos aos da base
									exportada</strong
								>), as demais colunas podem ser excluidas.
							</li>
							<li>
								Na coluna <strong>desconto</strong> (Desconto) o
								valor deve conter um número inteiro entre
								<strong>0</strong> e <strong>100</strong>.
								Qualquer valor não inteiro fora deste intervalo
								sera conciderado como <strong>0</strong>.
							</li>
							<li>
								A coluna <strong>mes</strong> não é considerada
								na importação do <strong>desconto</strong>.
							</li>
							<li>
								O <strong>mês</strong> considerado na importação
								do <strong>desconto</strong> é o mês selecionano
								no
								<strong>filtro da lista de fornecedores</strong
								>.
							</li>
						</ul>
					</v-alert>
					<v-row no-gutters>
						<v-col cols="8" class="px-2">
							<v-form ref="form" v-model="isCsv">
								<v-file-input
									label="Arquivo CSV"
									outlined
									dense
									v-model="arquivoCSV"
									:rules="regra.csv"
									accept=".csv"
								></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="4" class="px-2">
							<v-btn
								color="primary"
								:disabled="!isCsv"
								@click="importar()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv
										:data="erros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn color="primary">
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Erros
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<v-data-table
								:headers="headerErros"
								:items="erros"
								:items-per-page="5"
								:options.sync="paginationErros"
								class="elevation-0 data-table-2"
							></v-data-table>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import ControleEnvioGuia from './ControleEnvioGuia.vue';
export default {
	data() {
		return {
			headers: [
				{ text: "Código RD", value: "codigo", align: "center" },
				{
					text: "ID Fornecedor",
					value: "id_fornecedor",
					align: "center",
				},
				{ text: "Fornecedor", value: "nome", align: "center" },
				{ text: "CNPJ Matriz", value: "cnpj_matriz", align: "center" },
				{
					text: "Razão Social",
					value: "razao_social",
					align: "center",
				},
				{ text: "Mês", value: "mes_nome", align: "center" },
				{ text: "Desconto (%)", value: "desconto", align: "center" },
				{
					text: "Macro Fornecedor",
					value: "macrofornecedor",
					align: "center",
				},
				{
					text: "Ignora Acordo",
					value: "ignora_acordostr",
					align: "center",
				},
				{ text: "Negociação Comercial", value: "negociacao_comercial", align: "center" },
				{ text: "", value: "botao", align: "center", sortable: false },
			],
			dados: [],
			mes: null,
			fornecedor: {
				id_fornecedor: null,
				codigo: null,
				nome: null,
				desconto: null,
				id_macrofornecedor: null,
				ignora_acordo: null,
				negociacao_comercial: 'NÃO'
			},
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoImportar: false,
			dialogoEnvioGuias: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			lista: {
				macrofornecedor: [],
				ignora_acordo: []
			},
			regra: {
				desconto: [
					(v) =>
						(!!v && v >= 0 && v <= 100) ||
						"O desconto deve ser um numero inteiro ente 0 e 100.",
				],
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				mes: [(v) => !!v || "O campo mês é obrigatório."],
				ignora_acordo: [
					(v) => v !== null || "O campo ignora acordo é obrigatório.",
				],
				negociacao_comercial: [(v) => !!v || "O campo negociação comercial é obrigatório."],
			},
			isCsv: false,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.busca) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	async created() {
		this.mes = this.mesProximo;

		var response = await this.$http().post("/macrofornecedor/lista", {
			data: {},
		});
		const listaIgnoraAcordo = [
			{ ignora_acordo: false, nome: "NÃO" },
			{ ignora_acordo: true, nome: "SIM" },
		];

		this.lista.ignora_acordo = listaIgnoraAcordo;
		this.lista.macrofornecedor = response.data;
	},
	components: {ControleEnvioGuia},
	computed: {
		mesProximo() {
			return this.$moment()
				.add(1, "months")
				.format("YYYY-MM");
		},
	},
	methods: {
		async filtrar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			data.pagination = this.pagination;
			data.busca = this.busca;

			var response = await this.$http().post("/fornecedor/lista", {
				data: data,
			});

			this.dados = response.data;
			this.total = response.data.length ? response.data[0].total : 0;

			response.data.forEach((item) => {
				item.ignora_acordostr = item.ignora_acordo ? "SIM" : "NÃO";
			});

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			this.carregando = false;
		},
		async exportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var response = await this.$http().post("/fornecedor/exportar", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		async exportarImportacoes() {
			this.carregando = true;

			var response = await this.$http().post(
				"/fornecedor/exportarHistImportacoes",
				{ data: {} }
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		abrirEditar(item) {
			try {
                this.$refs.formEditar.resetValidation()
            } catch (error) {
                console.log(error)
            }
			this.fornecedor = { ...item };

			this.dialogo = true;
		},
		enviarGuias(item) {
			this.fornecedor = { ...item };
			this.dialogoEnvioGuias = true;
		},
		async editar() {
			if (!this.$refs.formFiltro.validate()) return;
			this.carregando = true;

			var data = this.fornecedor;

			var response = await this.$http().post("/fornecedor/editar", {
				data: data,
			});

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();

				this.dialogo = false;
			} else {
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}
		},
		abrirImportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.arquivoCSV = null;
			this.erro = false;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			this.dialogoImportar = true;
		},
		async importar() {
			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post(
				"/fornecedor/importar",
				formData
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				this.dialogoImportar = false;
			} else {
				this.filtrar();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
	},
};
</script>
