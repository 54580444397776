<template>
    <v-form
        v-on:submit.prevent="filtrar"
        ref="formFiltro"
        class="filtros-acordos"
    >
        <v-divider class="mt-2 mb-3"></v-divider>
        <v-row>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <MesInput
                    label="Período Inicial"
                    v-model="filtro.mesInicial"
                    :rules="regra.mesInicial"
                    :change="periodoInicialChange"
                    required
                ></MesInput>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <MesInput
                    label="Período Final"
                    v-model="filtro.mesFinal"
                    :rules="regra.mesFinal"
                    :change="periodoFinalChange"
                    required
                ></MesInput>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <v-text-field
                    label="Número do acordo"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="filtro.id_negociacao_comercial"
                ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <v-text-field
                    label="Número do acordo RD"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="filtro.acordo_rd"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-expansion-panels flat v-model="mostrarFiltros" accordion>
            <v-expansion-panel>
                <v-expansion-panel-content eager class="p-0 m-0">
                    <v-row>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-text-field
                                label="Número do contrato"
                                outlined
                                dense
                                class="input-cinza"
                                v-model="filtro.id_contrato_negociacao"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-text-field
                                label="Número do contrato RD"
                                outlined
                                dense
                                class="input-cinza"
                                v-model="filtro.contrato"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-select
                                label="Bandeira"
                                v-model="filtro.bandeira"
                                :items="lista.bandeira"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                            </v-select>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-autocomplete
                                label="Espaço"
                                v-model="filtro.id_espaco"
                                :items="lista.espaco"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                disabled
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            filtro.id_espaco.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            filtro.id_espaco.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_espaco.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-autocomplete
                                label="Fornecedor"
                                v-model="filtro.id_fornecedor"
                                :items="lista.fornecedor"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            filtro.id_fornecedor.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            filtro.id_fornecedor.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_fornecedor.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-select
                                label="Status"
                                :items="listaStatus"
                                v-model="filtro.id_status"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span
                                        v-if="
                                            filtro.id_status.length === 1 &&
                                            index === 0
                                        "
                                        >{{ item.text }}</span
                                    >
                                    <span
                                        v-if="
                                            filtro.id_status.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_status.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-select>
                        </v-col>

                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-autocomplete
                                label="Usuario"
                                v-model="filtro.id_usuario"
                                :items="lista.usuarios"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            filtro.id_usuario.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            filtro.id_usuario.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_usuario.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mt-0 mb-3"></v-divider>
        <v-row>
            <v-col cols="3" class="px-2 ml-auto">
                <div class="new-button d-flex align-center" text>
                    <span @click="expandirFiltros" style="cursor: pointer"
                        >Personalizar Filtros</span
                    >
                    <v-btn
                        style="background: #e6e6e6; width: 20px; height: 20px"
                        fab
                        icon
                        class="ml-3"
                        @click="expandirFiltros"
                        x-small
                    >
                        <v-icon>{{
                            mostrarFiltros == 0
                                ? 'mdi-chevron-up'
                                : 'mdi-chevron-down'
                        }}</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="9" class="ml-auto" align="end">
                <v-btn
                    class="ml-2 new-button"
                    color="yellow"
                    dark
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar Filtros
                </v-btn>
                <v-btn class="ml-5 new-button" color="primary" type="submit">
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
        </v-row>
        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-form>
</template>

<script>
import useFiltros from '../../../hooks/useFiltros'

export default {
    setup() {
        const { isLoading, isError, isFetching, lista, error, refetch } =
            useFiltros('/negociacaoComercial/listaCampos')

        return { isLoading, isError, isFetching, lista, error, refetch }
    },
    data() {
        return {
            carregando: false,
            mostrarFiltros: null,
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },

            filtro: {},
            regra: {
                mesInicial: [
                    (v) =>
                        (this.filtro.tipoPeriodo < 1 && !!v) ||
                        'O campo período inicial é obrigatório',
                ],
                mesFinal: [
                    (v) =>
                        (this.filtro.tipoPeriodo < 1 && !!v) ||
                        'O campo período final é obrigatório',
                    (v) =>
                        (this.filtro.tipoPeriodo < 1 &&
                            !!v &&
                            v >= this.filtro.mesInicial) ||
                        'O período final deve ser igual ou posterior ao período inicial',
                ],
            },
        }
    },
    async created() {
        await new Promise((resolve) => setTimeout(resolve, 100))
        this.iniciaFiltro()
    },
    computed: {
        listaStatus() {
            if (!this.lista.status) return []
            return [...this.lista.status].sort(function (a, b) {
                return a.text.localeCompare(b.text)
            })
        },
    },
    watch: {
        'lista.fornecedor': {
            handler: function (val, oldVal) {
                if (this.lista.fornecedor.length == 1) {
                    this.filtro.id_fornecedor = [this.lista.fornecedor[0].value]
                }
            },
            deep: true,
        },
    },
    methods: {
        iniciaFiltro() {
            this.filtro = {
                mesInicial: this.$moment().format('YYYY-MM'),
                mesFinal: this.$moment().format('YYYY-MM'),
                tipoPeriodo: -1,
                id_acordo: null,
                id_acordo_rd: null,
                id_contrato: null,
                contrato: null,
                bandeira: [],
                id_espaco: [],
                espaco_macro: [],
                id_fornecedor: [],
                id_status: [],
                id_usuario: [],
                id_cluster: [],
                visivel: null,
            }
        },
        limpar() {
            this.iniciaFiltro()

            this.$emit('limpar')
        },
        filtrar() {
            this.$emit('filtrar')
        },
        validate() {
            return this.$refs.formFiltro.validate()
        },
        expandirFiltros() {
            this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0
        },
        periodoInicialChange(valor) {
            if (this.filtro.mesFinal < valor) {
                this.filtro.mesFinal = valor
            }
        },
        periodoFinalChange(valor) {
            if (this.filtro.mesInicial > valor) {
                this.filtro.mesInicial = valor
            }
        },
        resetValidation() {
            try {
                this.$refs.formFiltro.resetValidation()
            } catch (error) {
                //console.log(error)
            }
        },
    },
}
</script>
