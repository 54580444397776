<template>
    <v-container fluid>
        <v-card class="px-5 pt-3 pb-0 br-15 w-100" elevation="0">
            <v-row>
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Inventário Móvel dermo</v-toolbar-title
                    >
                </v-col>

                <v-col cols="3" class="pb-0">
                    <MesInput
                        label="Mês"
                        class="pb-0"
                        v-model="mesFiltro"
                        required
                        hide-details
                    ></MesInput>
                </v-col>
                <div class="d-flekiul.x py-3 px-4">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                class="new-button"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="exportar()">
                                <v-list-item-title>
                                    Exportar
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportar(true)">
                                <v-list-item-title>
                                    Exportar Detalhado
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn
                        class="ml-5 new-button"
                        color="primary"
                        @click="abrirImportacao()"
                    >
                        <v-icon left>mdi-upload</v-icon> Importar
                    </v-btn>
                </div>
            </v-row>
        </v-card>
        <andamento ref="andamento" @filtrar="filtrar" />

        <v-card class="px-5 py-5 br-15 w-100 mt-5" elevation="0">
            <v-data-table
                class="elevation-0 tabela"
                :headers="headers"
                :items="dados"
                :items-per-page="10"
                :footer-props="tableConfig"
                :header-props="{ sortIcon: 'mdi-chevron-down' }"
                :server-items-length="pagination.total || 0"
                :options.sync="pagination"
                multi-sort
            >
            </v-data-table>
        </v-card>
        <importacao ref="importacao" @filtrar="filtrar"></importacao>
        <v-overlay :value="carregando">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import Andamento from './andamento.vue'
import importacao from './importacao.vue'
export default {
    components: { importacao, Andamento },
    data() {
        return {
            headers: [
                {
                    text: 'Espaço',
                    value: 'espaco',
                    align: 'center',
                    class: 'th-separador',
                },
                {
                    text: 'ID Java',
                    value: 'id_java',
                    align: 'center',
                    class: 'th-separador',
                },
                {
                    text: 'Bandeira',
                    value: 'bandeira',
                    align: 'center',
                    class: 'th-separador',
                },
                {
                    text: 'Marca',
                    value: 'marca',
                    align: 'center',
                    class: 'th-separador',
                },
                {
                    text: 'Fornecedor',
                    value: 'fornecedor',
                    align: 'center',
                    class: 'th-separador',
                },

                {
                    text: 'Mês',
                    value: 'mes',
                    align: 'center',
                    class: 'th-separador',
                },
            ],
            dados: [],
            mesFiltro: this.$moment().add(1, 'month').format('YYYY-MM'),
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            pagination: {},
            busca: '',
            carregando: false,
            tableConfig: {
                itemsPerPageOptions: [10, 15, 25, 50, 100],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            },
        }
    },
    watch: {
        pagination: {
            handler() {
                this.filtrar()
            },
            deep: true,
        },
        mesFiltro() {
            this.filtrar()
        },
        busca(v) {
            setTimeout(() => {
                if (v == this.busca) {
                    this.filtrar()
                }
            }, 1500)
        },
    },
    methods: {
        abrirImportacao() {
            this.$refs.importacao.abrirImportacao()
        },
        async exportar(detalhado = false) {
            this.carregando = true
            var data = { mes: [this.mesFiltro + '-01'], detalhado: detalhado }
            const response = await this.$http().post(
                '/movel_dermo/inventario/exportar',
                {
                    data: data,
                }
            )

            if (response.data.url) {
                window.location = this.$http('baseURL') + response.data.url
            }
            this.carregando = false
        },

        async filtrar(mesIndicado = null) {

            console.log('mesIndicado',mesIndicado)

            this.carregando = true
            this.$refs.andamento.filtrar()

            this.mesFiltro = mesIndicado ? mesIndicado : this.mesFiltro

            var data = { mes: [this.mesFiltro + '-01'] }

            data.pagination = this.pagination
            data.busca = this.busca

            const response = await this.$http().post(
                '/movel_dermo/inventario/lista',
                {
                    data: data,
                }
            )
            if (response.status == 200) {
                this.dados = response.data.data.lista
                this.pagination.total = response.data.data.total
            }
            this.carregando = false
        },
    },
}
</script>
