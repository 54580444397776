<template>
    <div v-if="andamento">
        <v-card class="px-5 br-15 my-5 w-100" elevation="0">
            <v-row>
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="">
                        <v-icon color="orange" size="32"
                            >mdi-alert-circle</v-icon
                        >

                        Importação de inventário em andamento do mês {{mes}}!</v-toolbar-title
                    >
                </v-col>
                <v-col class="flex-grow-1 d-flex justify-end">
                    <v-btn
                        class="new-button"
                        color="error"
                        @click="abrirDialogReprovar"
                    >
                        <v-icon size="22" left>mdi-close-circle</v-icon>
                        Reprovar inventário
                    </v-btn>
                    <v-btn
                        class="ml-5 new-button"
                        color="primary"
                        @click="abrirDialogAprovar"
                    >
                        <v-icon size="22" left>mdi-check-circle</v-icon>
                        Realizar {{ aprovacaoAtual }} aprovação
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3" @click="exportarDetalhes('filiais_total')">
                    <indicador
                        titulo="Linhas importadas"
                        :valor="indicadores.total"
                        icone="mdi-store"
                    >
                    </indicador>
                </v-col>
                <v-col cols="3" @click="exportarDetalhes('filiais_insercao')">
                    <indicador
                        titulo="Inserções"
                        :valor="indicadores.inseridas"
                        icone="mdi-store-plus"
                    >
                    </indicador>
                </v-col>
                <v-col cols="3" @click="exportarDetalhes('filiais_remocao')">
                    <indicador
                        titulo="Remoções"
                        :valor="indicadores.removidas"
                        icone="mdi-store-off"
                    >
                    </indicador>
                </v-col>
                <v-col
                    cols="3"
                    @click="exportarDetalhes('filiais_sem_acordos')"
                >
                    <indicador
                        titulo="Sem acordos"
                        :valor="indicadores.sem_acordo"
                        icone="mdi-file-document-plus-outline"
                    >
                    </indicador>
                </v-col>
            </v-row>
            <v-row> </v-row>
            <v-overlay :value="carregandoInterno" absolute>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-card>

        <v-dialog content-class="br-15" v-model="dialogReprovar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title> Reprovar inventário </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogReprovar = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    <p>
                        Você está prestes a reprovar o inventário. Deseja
                        continuar?
                    </p>
                    <p>
                        Ao reprovar o inventário, ele será removido da lista de
                        inventários em andamento. e será necessário realizar uma
                        nova importação.
                    </p>
                    <p>Atenção, esta ação não pode ser desfeita!</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="error"
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        @click="confirmarReprovar()"
                    >
                        Confirmar reprovação
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogAprovar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{
                            aprovacaoAtual == 'primeira'
                                ? tituloDialogoAprovacao
                                : tituloDialogoSegundaAprovacao
                        }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogAprovar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    <p>
                        Você está prestes realizar a primeira aprovação do
                        inventário.
                    </p>
                    <p>
                        Após a aprovação, será necessário realizar a segunda
                        aprovação. para finalizar o processo.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        @click="confirmarAprovar()"
                    >
                        <v-icon>mdi-check-circle</v-icon>
                        Confirmar aprovação
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :persistent="porcentagem < 100"
            content-class="br-15"
            v-model="dialogoAprovacao"
            width="800"
            style="z-index: 5000"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>Aprovação de inventário em curso</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="porcentagem < 100"
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoAprovacao = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <p class="text-center">
                        {{ mensagemProcessamento }}
                        <span v-if="!erroProcessamento"
                            >({{ porcentagem }}%)</span
                        >
                    </p>
                    <v-progress-linear
                        class="mb-3"
                        :color="erroProcessamento ? 'error' : 'teal'"
                        buffer-value="0"
                        :value="porcentagem"
                        stream
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-overlay :value="carregando" z-index="600">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import Indicador from './_components/Indicador.vue'
export default {
    components: { Indicador },
    data() {
        return {
            mes: '',
            carregando: false,
            carregandoInterno: false,
            dialogReprovar: false,
            dialogAprovar: false,
            tituloDialogoAprovacao: 'Realizar primeira aprovação',
            tituloDialogoSegundaAprovacao: 'Realizar segunda aprovação',
            andamento: false,
            indicadores: {},
            historico: [],
            dialogoAprovacao: false,
            erroProcessamento: false,
            porcentagem: 0,
            mensagemProcessamento: '',
        }
    },
    computed: {
        aprovacaoAtual() {
            return this.historico?.processo == 'primeira_aprovacao'
                ? 'segunda'
                : 'primeira'
        },
    },
    mounted() {
        this.filtrar()
    },
    methods: {
        abrirDialogReprovar() {
            this.dialogReprovar = true
        },
        abrirDialogAprovar() {
            this.dialogAprovar = true
        },

        async confirmarReprovar() {
     

            this.carregando = true
            const response = await this.$http().post(
                '/movel_dermo/inventario/reprovar'
            )
            this.carregando = false
            this.dialogAprovar = false

            this.filtrar()

            this.$dialog.notify.success('Reprovação realizada com sucesso', {
                position: 'top-right',
                timeout: 5000,
            })

            await this.filtrar()
            this.dialogReprovar = false
        },
        async confirmarAprovar() {
            if (this.aprovacaoAtual == 'segunda') {
                this.dialogAprovar = false;
                return this.segundaAprovacao()
            }
            this.carregando = true
            const data = {
                aprovacao: this.aprovacaoAtual,
            }
            const response = await this.$http().post(
                '/movel_dermo/inventario/aprovar',
                {
                    data: data,
                }
            )
            this.carregando = false
            this.dialogAprovar = false

            this.filtrar()

            this.$dialog.notify.success('Aprovação realizada com sucesso', {
                position: 'top-right',
                timeout: 5000,
            })
        },
        async exportarDetalhes(tipo) {
            this.carregando = true
            const data = { mes: ['2023-08-01'] }
            data.tipo = tipo
            const response = await this.$http().post(
                '/movel_dermo/inventario/exportarDetalhes',
                {
                    data: data,
                }
            )

            if (response.data.url) {
                window.location = this.$http('baseURL') + response.data.url
            }
            this.carregando = false
        },
        async exportar() {
            this.carregando = true
            var data = { mes: ['2023-08-01'] }
            const response = await this.$http().post(
                '/movel_dermo/inventario/exportar',
                {
                    data: data,
                }
            )
            await new Promise((resolve) => setTimeout(resolve, 1000))

            if (response.data.url) {
                window.location = this.$http('baseURL') + response.data.url
            }
            this.carregando = false
        },
        async getAndamento() {
            const response = await this.$http().post(
                '/movel_dermo/inventario/andamento'
            )
            await new Promise((resolve) => setTimeout(resolve, 1000))
            if (response.status == 200) {
                this.andamento = response.data.andamento
                this.historico = response.data.historico
            }
        },
        async getIndicadores() {
            const response = await this.$http().post(
                '/movel_dermo/inventario/indicadores'
            )
            await new Promise((resolve) => setTimeout(resolve, 1000))
            if (response.status == 200) {
                this.indicadores = response.data.indicadores
                this.mes = this.$moment(response.data.indicadores.mes).format('MM/YYYY')
            }
        },
        async segundaAprovacao() {
            this.dialogoAprovacao = true
            this.erroProcessamento = false
            const data = {
                aprovacao: this.aprovacaoAtual,
            }
            const response = await this.$http().post(
                '/movel_dermo/inventario/aprovar',
                {
                    data: data,
                },
                {
                    onDownloadProgress: (progressEvent) => {
                        const retorno =
                            progressEvent.currentTarget.response.split('\n')
                        console.log(progressEvent)
                        const data = JSON.parse(retorno[retorno.length - 2])
                        console.log(data)
                        this.porcentagem = data.porcentagem
                        this.mensagemProcessamento = data.mensagem
                    },
                }
            )

            console.log(response)
            if (response.status !== 200) {
                this.mensagemProcessamento =
                    'Erro ao aprovar inventário. Tente novamente.'
                this.porcentagem = 100
                this.erroProcessamento = true
            }
            this.carregando = false
            this.$emit('filtrar')

        },
        async filtrar() {
            this.carregandoInterno = true
            await this.getAndamento()

            if (this.andamento) {
                await this.getIndicadores()
            }

            this.carregandoInterno = false
        },
    },
}
</script>
