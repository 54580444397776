<template>
    <v-container class="pt-0" fluid>
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col cols="8">
                    <v-toolbar-title class="page-title"
                        >Negociação comercial</v-toolbar-title
                    >
                </v-col>
                <v-col cols="2">
                    <v-tooltip top :disabled="!travaInventario">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <v-btn
                                    class="new-button w-100"
                                    color="primary"
                                    :disabled="travaInventario"
                                    @click="$refs.formulario.novo(usuarioTipo)"
                                    v-if="['rd'].includes(usuarioTipo)"
                                >
                                    <v-icon left>mdi-plus-thick</v-icon> Criar
                                    acordo
                                </v-btn>
                            </div>
                        </template>
                        <span
                            >Criação de acordo desabilitada até a finalização da
                            aprovação do inventário.</span
                        >
                    </v-tooltip>
                </v-col>
                <v-col cols="2">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                class="new-button w-100"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="exportar()">
                                <v-list-item-title
                                    >Exportação Acordos</v-list-item-title
                                >
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <filtro ref="filtro" @filtrar="filtrar" @limpar="limpar"></filtro>
        </v-card>
        <indicadores
            v-if="usuarioTipo == 'rd'"
            ref="indicadores"
            :indicadores="indicadores"
            :filtro="$refs.filtro"
        ></indicadores>

        <v-card elevation="0" class="br-15 pa-5 involucroAcordo">
            <v-data-table
                v-model="acordoSelecionado"
                :headers="headers"
                :items="acordos"
                :server-items-length="totalAcordos"
                item-key="id_negociacao_comercial"
                show-select
                fixed-header
                multi-sort
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100, 500, -1],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                }"
                :options.sync="pagination"
                class="tabela paginacao-esquerda flex-table"
            >
            <template v-slot:[`item.investimento`]="{ item }">
                <formataReal :valor="item.investimento" />
                </template>
                <template
                    v-slot:[`item.data-table-select`]="{
                        item,
                        isSelected,
                        select,
                    }"
                >
                    <v-simple-checkbox
                        :ripple="false"
                        :value="isSelected"
                        color="primary"
                        :disabled="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                        "
                        :off-icon="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                                ? '$checkboxLocked'
                                : 'mdi-checkbox-blank-outline'
                        "
                        :on-icon="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                                ? '$checkboxLocked'
                                : 'mdi-checkbox-marked'
                        "
                        @input="select($event)"
                    ></v-simple-checkbox>
                  
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu
                        offset-y
                        min-width="180"
                        content-class=" acordo-menu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                color="#f2f2f2"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                                style="min-width: unset"
                            >
                                <v-icon color="#CCC">mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list dense nav>
                                <v-list-item
                                    v-if="
                                        statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) &&
                                        fnAprovar &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.reprovar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentRemove</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Reprovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) &&
                                        fnAprovar &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.aprovar(item)"
                                    class="hover-red"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentCheck</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Aprovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.mes_acordo > mesAtual &&
                                        statusAcesso(
                                            item.id_status,
                                            'editar'
                                        ) &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.formulario.editar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-pencil</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Editar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    class="pl-2 mr-0"
                                    v-if="['rd', 'cubo'].includes(usuarioTipo)"
                                    link
                                    @click="$refs.dialogo.historico(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Histórico</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.editar(item, true)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exibir Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.termino == item.mes_acordo &&
                                        item.termino >= mesAnterior &&
                                        statusAcesso(
                                            item.id_status,
                                            'renovar'
                                        ) &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.renovacao(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-update</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Renovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="!travaInventario && (item.mes_acordo >= mesAtual &&
                                    statusAcesso(
                                        item.id_status,
                                        'replicar_cancelado'
                                    ) &&
                                    usuarioTipo == 'rd')
                                    " link @click="
                                        $refs.formulario.replicarAcordo(
                                            item,
                                            'cancelado'
                                        )
                                        ">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-autorenew</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Replicar
                                            Cancelado</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.obs_departamento &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="
                                        $refs.dialogo.dialogDepartamento(item)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-clipboard-text-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Obs.
                                            Departamento</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.obs_contrato"
                                    link
                                    @click="$refs.dialogo.dialogContrato(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-clipboard-text</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Obs. Contrato</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        statusAcesso(
                                            item.id_status,
                                            'cancelar'
                                        ) && usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.cancelar(item)"
                                    class="hover-red"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentRemove</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Cancelar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        fnLiberar &&
                                        statusAcesso(
                                            item.id_status,
                                            'liberar'
                                        ) &&
                                        item.mes_acordo == mesAtual &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="dialogoLiberarAcordos(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >$documentCheck</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Liberar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
            </v-data-table>
            <div
                v-if="usuarioTipo == 'rd'"
                class="table-footer-prepend d-flex pl-2 pt-4 align-center justify-end"
            >
                <v-btn
                    v-if="!fnLiberar"
                    class="new-button ml-5"
                    :disabled="
                        !acordoSelecionado.filter((e) =>
                            statusAcesso(e.id_status, 'aprovar_reprovar')
                        ).length || !fnAprovar
                    "
                    color="primary"
                    @click="$refs.dialogo.aprovar()"
                >
                    <v-icon left>mdi-check-circle</v-icon> Aprovar Acordos
                </v-btn>
                <v-btn
                    class="new-button ml-5"
                    v-if="!fnLiberar"
                    :disabled="
                        !acordoSelecionado.filter((e) =>
                            statusAcesso(e.id_status, 'aprovar_reprovar')
                        ).length || !fnAprovar
                    "
                    color="error"
                    @click="$refs.dialogo.reprovar()"
                >
                    <v-icon left>mdi-close-circle</v-icon> Reprovar Acordos
                </v-btn>
                <v-btn
                    v-if="fnLiberar"
                    class="new-button ml-5"
                    :disabled="
                        !acordoSelecionado.filter(
                            (e) =>
                                statusAcesso(e.id_status, 'liberar') &&
                                e.mes_acordo == mesAtual &&
                                usuarioTipo == 'rd'
                        ).length
                    "
                    color="primary"
                    @click="dialogoLiberarAcordos()"
                >
                    <v-icon left>mdi-check</v-icon> Liberar acordos
                </v-btn>
            </div>
        </v-card>
        <dialogo
            ref="dialogo"
            @aprovarAcordo="aprovarAcordo"
            @liberarAcordo="liberarAcordo"
            @filtrar="filtrar"
            @renovar="renovar"
        ></dialogo>

        <formulario ref="formulario" @filtrar="filtrar"></formulario>

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import useFiltros from '../../../hooks/useFiltros'
import Indicadores from './_components/Indicadores.vue'
import Formulario from './formulario/formulario.vue'
import Filtro from './Filtro.vue'
import Dialogo from './Dialogo.vue'

export default {
    setup() {
        const { isLoading, isError, isFetching, lista, error, refetch } =
            useFiltros('/negociacaoComercial/listaCampos')

        return { isLoading, isError, isFetching, lista, error, refetch }
    },
    components: {
        Indicadores,
        Formulario,
        Filtro,
        Dialogo,
    },
    data() {
        return {
            usuarioTipo: 'rd',
            acordoSelecionado: [],
            acordos: [],
            totalAcordos: 0,
            indicadores: {},
            carregando: false,
            status: [],
            fnAprovar: false,
            fnLiberar: false,
            pagination: {
                // sortBy: ['id_negociacao_comercial'],
                sortDesc: [true],
            },
        }
    },
    provide() {
        return {
            listaCampos: this.listaCampos(),
            usuarioTipo: this.usuarioTipo,
        }
    },
    computed: {
        travaInventario() {
            let a = 1
            if (1 == a) {
                return false
            }
            if (this.lista.inventarioUltimoHistorico) {
                return this.lista.inventarioUltimoHistorico.importacaoAndamento
            }
            return true
        },
        mesAtual() {
            return this.$moment().format('YYYY-MM')
        },
        mesAnterior() {
            return this.$moment().subtract(1, 'months').format('YYYY-MM')
        },
        mesProximo() {
            return this.$moment().add(1, 'months').format('YYYY-MM')
        },

        headers: () => [
            {
                text: 'Menu',
                value: 'actions',
                sortable: false,
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Tipo de Acordo',
                value: 'tipo_acordo',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Acordo',
                value: 'id_negociacao_comercial',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'ID integração',
                value: 'id_integracao',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Acordo RD',
                value: 'acordo_rd',
                align: 'center',
                class: 'th-separador   ',
            },

            {
                text: 'Fornecedor',
                value: 'fornecedor',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Espaço',
                value: 'espaco',
                align: 'center',
                class: 'th-separador   ',
            },

            {
                text: 'Bandeira',
                value: 'bandeira',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Mês',
                value: 'mes',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Ano',
                value: 'ano',
                align: 'center',
                class: 'th-separador   ',
            },

            {
                text: 'Contrato',
                value: 'id_contrato_negociacao',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Contrato RD',
                value: 'contrato',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Parcela',
                value: 'parcela',
                sortable: false,
                align: 'center',
                class: 'th-separador   ',
            },

            {
                text: 'Status',
                value: 'status',
                align: 'center',
                class: 'th-separador   ',
            },

            {
                text: 'Just. Reprov.',
                value: 'justificativa_reprovacao',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Just. Cancel.',
                value: 'justificativa_cancelamento',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Produtos',
                value: 'produtos',
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Prod. Edit.',
                value: 'produtos_alterados',
                align: 'center',
                class: 'th-separador   ',
            },

            {
                text: 'Investimento',
                value: 'investimento',
                align: 'center',
                class: 'th-separador   ',
            },

            {
                text: 'Usuário que Criou',
                value: 'usuario_criacao',
                sortable: true,
                align: 'center',
                class: 'th-separador   ',
            },
            {
                text: 'Cód. Fornecedor',
                value: 'codigo',
                align: 'center',
                class: 'th-separador   ',
            },
        ],
    },
    async mounted() {
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, 200)
        })
        this.filtrar()
    },
    async beforeCreate() {
        const response = await this.$http().post('/acordo/status', {})

        this.status = response.data

        const response2 = await this.$http().post('/acordo/permissao', {
            id_menu: 2,
        })

        this.fnAprovar = response2.data
    },
    methods: {
        async listaCampos() {
            var response = await this.$http().post(
                '/negociacaoComercial/listaCampos',
                {}
            )

            return response.data
        },
        limpar() {
            // this.planograma = []
            this.statusIndicadores = []
            // this.fnLiberar = false;

            this.filtrar()
        },

        statusAcesso(id_status, acesso) {
            if (this.status) {
                if (id_status === 30 && acesso === 'editar_planograma')
                    return false

                var status = this.status.find((e) => e.id_status == id_status)

                return status[acesso]
            } else {
                return false
            }
        },
        async filtrar(paginaInicial = true) {
            if (!this.$refs.filtro.validate()) return

            this.carregando = true

            this.acordoSelecionado = []

            const filtro = this.$refs.filtro.filtro
            const data = {}

            data.mesInicial = filtro.mesInicial

            data.mesFinal = filtro.mesFinal

            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1

            data.bandeira = filtro.bandeira
            data.id_espaco = filtro.id_espaco
            data.id_fornecedor = filtro.id_fornecedor
            data.id_status = filtro.id_status ? filtro.id_status : []
            data.id_negociacao_comercial = filtro.id_negociacao_comercial
                ? [filtro.id_negociacao_comercial]
                : []
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : []
            data.id_usuario_criacao = filtro.id_usuario_criacao
                ? filtro.id_usuario_criacao
                : []
            data.id_contrato = filtro.id_contrato ? [filtro.id_contrato] : []
            data.contrato = filtro.contrato ? [filtro.contrato] : []

            if (
                data.id_status.length > 0 &&
                data.id_status.every((status) => status === 29)
            ) {
                console.log(data.id_status)
                this.fnLiberar =
                    true &&
                    [1, 2].includes(this.$store.getters.getUsuario.id_cargo)
            } else {
                this.fnLiberar = false
            }

            if (paginaInicial) this.pagination.page = 1

            data.pagination = this.pagination

            var response = await this.$http().post(
                '/negociacaoComercial/lista',
                {
                    data: data,
                }
            )

            this.acordos = response.data.lista
            this.totalAcordos = response.data.total
            this.indicadores = response.data.indicadores

            if (this.acordos.length == 0 && this.pagination.page > 1) {
                this.filtrar()
            }

            this.carregando = false
        },
        async exportar() {
            if (!this.$refs.filtro.validate()) return

            this.carregando = true

            const filtro = this.$refs.filtro.filtro

            const data = {}

            data.mesInicial = filtro.mesInicial
            data.mesFinal = filtro.mesFinal
            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1

            data.bandeira = filtro.bandeira
            data.id_espaco = filtro.id_espaco
            data.espaco_macro = filtro.espaco_macro
            data.id_fornecedor = filtro.id_fornecedor
            data.id_status = filtro.id_status ? filtro.id_status : []
            data.id_negociacao_comercial = filtro.id_negociacao_comercial
                ? [filtro.id_negociacao_comercial]
                : []
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : []
            data.id_contrato = filtro.id_contrato ? [filtro.id_contrato] : []
            data.contrato = filtro.contrato ? [filtro.contrato] : []

            const response = await this.$http().post(
                '/negociacaoComercial/exportar',
                {
                    data: data,
                }
            )

            window.location = this.$http('baseURL') + response.data.url

            this.carregando = false
        },
        async liberarAcordo(acordos) {
            this.carregando = true

            var response = await this.$http().post(
                '/negociacaoComercial/liberarAcordo',
                {
                    data: {
                        acordos: acordos,
                    },
                }
            )

            if (response.data.sucesso) {
                this.filtrar(false)
            } else {
                // this.alerta = true;
                // this.alertaColor = "error";
                // this.alertaTimeout = -1;
                // this.alertaTexto = response.data.msg;
                this.dialogErroLiberacao = true
                this.errosImportacao = response.data.erros
            }

            this.acordoSelecionado = []
            this.carregando = false
        },
        dialogoLiberarAcordos(acordo = null) {
            let acordos = acordo
                ? [acordo]
                : this.acordoSelecionado.filter((e) =>
                      this.statusAcesso(e.id_status, 'liberar')
                  )
            const total = acordos.reduce(
                (a, e) => (a += parseFloat(e.investimento)),
                0
            )
            acordos = acordos.map((a) => a.id_negociacao_comercial)
            console.log(acordos)
            this.$refs.dialogo.liberar(acordos, total)
        },
        // liberarEmMassa() {
        //     const acordos = this.acordoSelecionado
        //         .filter((e) => this.statusAcesso(e.id_status, "liberar"))
        //         .map((a) => a.id_negociacao_comercial);
        //     const investimento = this.acordoSelecionado.reduce((a,e) => a += parseFloat(e.investimento),0)
        //     // console.log(investimento)
        //     // console.log("selecionados: ", this.acordoSelecionado);
        //     // console.log("acordos: ", acordos);
        //     this.$refs.dialogo.liberar(acordos,investimento);
        // },
        renovar(data) {
            this.$refs.formulario.editar(data.item)
        },
        async aprovarAcordo(data) {
            this.carregando = true

            data.acordos = data.id_negociacao_comercial
                ? [data.id_negociacao_comercial]
                : this.acordoSelecionado
                      .filter((e) =>
                          this.statusAcesso(e.id_status, 'aprovar_reprovar')
                      )
                      .map((a) => a.id_negociacao_comercial)

            var response = await this.$http().post(
                '/negociacaoComercial/aprovarAcordo',
                {
                    data: data,
                }
            )

            if (response.data.sucesso) {
                this.filtrar(false)
                console.log('Success')
            } else {
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }

            this.acordoSelecionado = []
            this.carregando = false
        },
    },
}
</script>
