<template>
    <div>
        <v-dialog content-class="br-15" v-model="dialog" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialog = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    {{ dialogTexto }}
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogoResumo" width="90%">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoResumo = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <div class="resumo">
                        <div class="resumo-conteudo">
                            <div class="resumo-item">
                                <span class="titulo">Cadastro do Acordo:</span>
                                <p>
                                    Periodo de
                                    <strong>{{
                                        formatarMes(acordo.mes_acordo)
                                    }}</strong>
                                    até
                                    <strong>{{
                                        formatarMes(acordo.termino)
                                    }}</strong>
                                </p>
                                <p>
                                    Espaço: <strong>{{ acordo.espaco }}</strong>
                                </p>
                                <p>
                                    Plano: <strong>{{ acordo.plano }}</strong>
                                </p>
                                <p>
                                    Bandeira:
                                    <strong>{{ acordo.bandeira }}</strong>
                                </p>
                                <!-- <p>
                                    Categoria:
                                    <strong>{{ acordo.categoria }}</strong>
                                </p>
                                <p>
                                    Device:
                                    <strong>{{ acordo.device }}</strong>
                                </p> -->
                            </div>
                            <v-divider />
                            <div class="resumo-item">
                                <span class="titulo">Seleção de Produtos:</span>
                                <p>
                                    Fornecedor:
                                    <strong>{{ acordo.fornecedor }}</strong>
                                </p>
                                <p>
                                    Produtos:
                                    <strong>{{ acordo.produtos }}</strong>
                                </p>
                            </div>
                            <v-divider />
                            <div class="resumo-item">
                                <p class="titulo">Valores:</p>
                                <p>
                                    Total de investimento:
                                    <strong>
                                        <formataReal :valor="acordo.valor"
                                    /></strong>
                                </p>
                                <p>
                                    Desconto:
                                    <strong
                                        ><formataReal
                                            :valor="acordo.desconto_total"
                                    /></strong>
                                </p>
                                <p v-if="acordo.desconto_percentual">
                                    Desconto percentual:
                                    <strong>
                                        {{ acordo.desconto_percentual }}%
                                    </strong>
                                </p>
                                <p v-else>
                                    Desconto padrão:
                                    <strong>
                                        {{ acordo.desconto_padrao || 0 }}%
                                    </strong>
                                </p>
                                <p>
                                    Total com desconto:
                                    <strong
                                        ><formataReal
                                            :valor="acordo.investimento"
                                    /></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogImagem" width="600">
            <v-card>
                <v-card-title class="subtitle-1 primary-degrade white--text">
                    {{ dialogTitulo }}
                </v-card-title>
                <v-card-text class="mt-4" v-html="htmlPlanograma">
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialogImagem = false">
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogRenovar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogRenovar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mt-4 mb-0 pb-0">
                    Selecione a data final de
                    <strong style="color: var(--v-primary-base)"
                        >renovação</strong
                    >
                    do acordo.
                    <br />
                    <br />
                    <v-form v-model="isRenovar">
                        <MesInput
                            v-model="mesFinal"
                            :min="termino"
                            label="Selecionar Período"
                            :rules="regra.renovar"
                            required
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        class="new-button px-6 mb-3 mr-2"
                        @click="renovarAcordo(acordoRenovar.bandeira)"
                        :disabled="!isRenovar"
                    >
                        Renovar apenas {{ acordoRenovar.bandeira }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="new-button px-6 mb-3 mr-2"
                        @click="renovarAcordo()"
                        :disabled="!isRenovar"
                    >
                        Renovar ambas bandeiras
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-dialog
                content-class="br-15"
                v-model="dialogoErro.open"
                persistent
                width="90%"
            >
                <v-card>
                    <v-card-title class="subtitle-1 error white--text">
                        Erro(s) ao Salvar Acordo(s)
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro"
                            class="tabela"
                        ></v-data-table>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <download-csv
                            :data="listaErro"
                            :labels="listaErroLabels"
                            name="erros.csv"
                            delimiter=";"
                        >
                            <v-btn color="primary">
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </download-csv>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="fecharErros(dialogoErro.area)"
                        >
                            {{ dialogoErro.label }}
                            <v-icon left v-if="dialogoErro.area != 'renovar'"
                                >mdi-close</v-icon
                            >
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogEditar" width="90%">
            <v-card>
                <v-card-title class="subtitle-1 primary-degrade white--text">
                    {{ dialogTitulo }}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-row no-gutters>
                        <v-col cols="4" class="mb-2">
                            <b>Bandeira:</b> {{ item.bandeira }}
                        </v-col>
                        <v-col cols="8" class="mb-2">
                            <b>Fornecedor:</b> {{ item.fornecedor }}
                        </v-col>
                        <v-col cols="4" class="mb-2">
                            <b>Espaço:</b> {{ item.espaco }}
                        </v-col>
                        <v-col cols="8" class="mb-2">
                            <b>Modelo de Check-Out:</b> {{ item.check_out }}
                        </v-col>
                        <v-col cols="12" class="mb-2">
                            <b>Selecione uma imagem ou PDF do Planograma.</b>
                        </v-col>
                        <v-col cols="12">
                            <v-form v-model="isPlanograma">
                                <v-file-input
                                    v-model="filePlanograma"
                                    label="Planograma"
                                    outlined
                                    dense
                                ></v-file-input>
                            </v-form>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="item.comentario"
                                outlined
                                dense
                                rows="3"
                                label="Comentários Gerais"
                            ></v-textarea>
                        </v-col>
                        <v-col
                            cols="12"
                            class="mb-2"
                            v-show="item.obs_departamento"
                        >
                            <b>Observação Departamento:</b>
                            {{ item.obs_departamento }}
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headersProdutos"
                        :items="dadosProdutos"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                        }"
                        class="elevation-0"
                    ></v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="dialogEditar = false">
                        Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="!isPlanograma"
                        @click="editarPlanograma()"
                    >
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogAprovar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary degrade white--text fix-flex"
                >
                    <v-toolbar-title>{{ dialogTitulo }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogAprovar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mt-6">
                    {{ dialogTexto }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        color="error"
                        @click="dialogAprovar = false"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Não, não aprovar
                    </v-btn>
                    <v-btn
                        class="new-button mb-2 px-3"
                        color="primary"
                        @click="aprovarAcordo('aprovar')"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, aprovar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogReprovar" width="600">
            <v-card>
                <!-- <v-card-title class="subtitle-1 error white--text">
          {{ dialogTitulo }}
        </v-card-title> -->
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogReprovar = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    ˝
                    <v-form v-model="isJustificativa">
                        <v-select
                            label="Justificativa"
                            v-model="justificativa"
                            :items="justificativasReprovacao"
                            :rules="regra.justificativa"
                            :menu-props="menuProps"
                            class="input-cinza"
                            outlined
                            dense
                        ></v-select>
                        <v-textarea
                            outlined
                            label="Observação"
                            class="input-cinza"
                            v-model="observacao"
                        ></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        :disabled="!isJustificativa"
                        @click="aprovarAcordo('reprovar')"
                    >
                        Reprovar Acordo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogHistorico" width="80%">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogHistorico = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div class="pa-5 pb-o">
                    <v-data-table
                        :headers="headersHistorico"
                        :items="dessertsHistorico"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        }"
                        item-key="id"
                        :items-per-page="5"
                        class="tabela"
                    ></v-data-table>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogCancelar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogCancelar = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mt-4">
                    Deseja realmente <span style="color: red">cancelar</span> o
                    acordo?
                    <br />
                    <br />
                    <v-form v-model="isJustificativa">
                        <v-select
                            label="Justificativa"
                            v-model="justificativa"
                            :items="justificativasCancelamento"
                            :rules="regra.justificativa"
                            class="input-cinza"
                            :menu-props="menuProps"
                            outlined
                            dense
                        ></v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="new-button mr-3 px-4 mb-2"
                        @click="dialogCancelar = false"
                    >
                        <v-icon class="mr-3">mdi-close-circle</v-icon>

                        Não, não quero cancelar
                    </v-btn>
                    <v-btn
                        class="new-button px-4 mb-2"
                        color="primary"
                        @click="cancelarAcordo()"
                        :disabled="!isJustificativa"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, quero cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialogo liberar acordos -->
        <v-dialog content-class="br-15" v-model="dialogLiberar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Liberar acordos </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogLiberar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-6">
                    <div>
                        Deseja realmente
                        <strong style="color: var(--v-primary-base)"
                            >liberar</strong
                        >
                        os acordos selecionados, juntos eles somam o total
                        investido de:
                        <strong style="color: var(--v-primary-base)">
                            {{ investimento }}</strong
                        >
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        color="error"
                        @click="dialogLiberar = false"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Não, não liberar
                    </v-btn>
                    <v-btn
                        class="new-button mb-2 px-3"
                        color="primary"
                        @click="liberarAcordo()"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, liberar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Fim dialogo liberar acordos -->
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    inject: ['listaCampos'],
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            item: {},
            acordo: {
                mes_acordo: null,
            },
            acordoRenovar: {},
            id_negociacao_comercial: null,
            dialog: false,
            dialogImagem: false,
            dialogRenovar: false,
            dialogHistorico: false,
            dialogEditar: false,
            dialogAprovar: false,
            dialogReprovar: false,
            dialogCancelar: false,
            dialogLiberar: false,
            dialogoResumo: false,
            dialogTitulo: null,
            dialogTexto: null,
            htmlPlanograma: null,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            carregando: false,
            acordosALiberar: [],
            investimento: '',
            regra: {
                justificativa: [(v) => !!v || 'Selecione uma justificativa.'],
                renovar: [
                    (v) =>
                        !!v || 'Selecione a data final de renovação do acordo.',
                    (v) =>
                        (v && v >= this.mesAtual) ||
                        'A data final de renovação do acordo deve ser igual ou posterior ao mês atual',
                ],
                modelo: [
                    (v) => !!v || 'O campo modelo check-out é obrigatório',
                ],
            },
            filePlanograma: null,
            isPlanograma: false,
            justificativa: null,
            observacao: null,
            isJustificativa: false,
            justificativasReprovacao: [],
            justificativasCancelamento: [],
            headersHistorico: [
                {
                    text: 'Processo',
                    value: 'processo',
                    sortable: false,
                    align: 'center',
                    class: 'th-separador',
                },
                {
                    text: 'Detalhes',
                    value: 'detalhe',
                    sortable: false,
                    align: 'center',
                    class: 'th-separador',
                },
                {
                    text: 'Data',
                    value: 'data',
                    sortable: false,
                    align: 'center',
                    class: 'th-separador',
                },
                {
                    text: 'Usuário',
                    value: 'usuario',
                    sortable: false,
                    align: 'center',
                },
            ],
            dessertsHistorico: [],
            termino: null,
            mesFinal: null,
            modelo_ck: null,
            clusters_acordo: [],
            lista: {
                modelos_ck: [],
            },
            isRenovar: false,
            dialogoErro: {
                open: false,
                label: 'FECHAR',
                area: '',
                data: [],
            },
            listaHeaderErro: [
                { text: 'Descrição do Erro', value: 'msg', align: 'left' },
            ],
            listaErro: [],
            listaErroLabels: { msg: 'Descrição do Erro' },
            paginationErro: {
                page: 1,
            },
            dadosProdutos: [],
            headersProdutos: [
                {
                    text: 'Código',
                    value: 'codigo',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'Marca',
                    value: 'marca',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'Produto',
                    value: 'nome',
                    sortable: false,
                    align: 'center',
                },
                { text: 'VB', value: 'vb', sortable: false, align: 'center' },
            ],
        }
    },
    async created() {
        var lista = await this.listaCampos
        this.justificativasReprovacao = [
            { text: '', value: '' },
            ...lista.justificativasReprovacao,
        ]
        this.justificativasCancelamento = [
            { text: '', value: '' },
            ...lista.justificativasCancelamento,
        ]
    },
    computed: {
        mesAtual() {
            return this.$moment().format('YYYY-MM')
        },
    },
    methods: {
        dialogDepartamento(item) {
            this.dialog = true
            this.dialogTitulo =
                'Obs. Departamento Acordo (' +
                item.id_negociacao_comercial +
                ')'
            this.dialogTexto = item.obs_departamento
        },
        dialogContrato(item) {
            this.dialog = true
            this.dialogTitulo =
                'Obs. Contrato Acordo (' + item.id_negociacao_comercial + ')'
            this.dialogTexto = item.obs_contrato
        },
        dialogResumo(item) {
            this.dialogoResumo = true
            this.dialogTitulo =
                'Resumo Acordo (' + item.id_negociacao_comercial + ')'
            this.acordo = item
        },
        formatarMes(mes) {
            return this.$moment(mes, 'YYYY-MM')
                .format('MMMM [de] YYYY')
                .capitalize()
        },

        renovacao(item) {
            this.acordoRenovar = item
            this.modelo_ck = null
            this.lista.modelos_ck = []
            this.clusters_acordo = []

            var termino = new Date(item.termino + '-15')
            termino.setMonth(termino.getMonth() + 1)
            this.termino =
                termino.getFullYear() +
                '-' +
                ('0' + (termino.getMonth() + 1)).slice(-2)

            // if (
            //     item.organico == 1 &&
            //     (item.espaco_macro || "").toLowerCase() == "check-out" &&
            //     (item.check_out_modelo || "") == ""
            // ) {
            //     let _ckout = (item.check_out || "").replace(/ /g, "");
            //     this.lista.modelos_ck = _ckout != "" ? _ckout.split(",") : [];
            // }

            // if (this.lista.modelos_ck.length == 1)
            //     this.modelo_ck = this.lista.modelos_ck[0];

            // if ((item.check_out_modelo || "") != "") {
            //     this.modelo_ck = item.check_out_modelo;
            //     this.lista.modelos_ck = [item.check_out_modelo];
            // }

            // if (item.organico == 1) this.clusters_acordo = item.clusters_acordo;

            this.id_negociacao_comercial = item.id_negociacao_comercial
            this.mesFinal = null
            this.dialogRenovar = true
            this.dialogTitulo =
                'Renovar Acordo (' + item.id_negociacao_comercial + ')'
        },
        async historico(item) {
            this.carregando = true

            this.dessertsHistorico = []

            this.dialogTitulo =
                'Histórico (' + item.id_negociacao_comercial + ')'

            var response = await this.$http().post(
                '/negociacaoComercial/historico',
                {
                    data: {
                        id_negociacao_comercial: item.id_negociacao_comercial,
                    },
                }
            )

            this.dessertsHistorico = response.data

            this.dialogHistorico = true
            this.carregando = false
        },
        async aprovar(item) {
            this.justificativa = null
            this.observacao = null
            this.dialogAprovar = true

            if (item) {
                this.id_negociacao_comercial = item.id_negociacao_comercial
                this.dialogTitulo =
                    'Aprovar Acordo Fora do Padão (' +
                    item.id_negociacao_comercial +
                    ')'
                this.dialogTexto = 'Deseja realmente aprovar o acordo.'
            } else {
                this.id_negociacao_comercial = null
                this.dialogTitulo = 'Aprovar Acordos'
                this.dialogTexto =
                    'Deseja realmente aprovar o(s) acordo(s) selecionados.'
            }
        },
        reprovar(item) {
            this.justificativa = null
            this.observacao = null
            this.dialogReprovar = true

            if (item) {
                this.id_negociacao_comercial = item.id_negociacao_comercial
                this.dialogTitulo =
                    'Reprovar Acordo (' + item.id_negociacao_comercial + ')'
                this.dialogTexto =
                    'Selecione uma justificativa para a reprovação do acordo.'
            } else {
                this.id_negociacao_comercial = null
                this.dialogTitulo = 'Reprovar Acordos'
                this.dialogTexto =
                    'Selecione uma justificativa para a reprovação do(s) acordo(s).'
            }
        },
        async aprovarAcordo(tipo) {
            var data = {
                tipo: tipo,
                id_negociacao_comercial: this.id_negociacao_comercial,
            }

            if (tipo == 'reprovar') {
                console.log(this.justificativa)
                const justificativa = this.justificativasReprovacao.find(
                    (item) => item.value == this.justificativa
                )

                data.id_justificativa = this.justificativa
                data.justificativa = justificativa.text
                data.observacao = this.observacao
            }

            this.$emit('aprovarAcordo', data)

            this.dialogAprovar = false
            this.dialogReprovar = false
        },

        async renovarAcordo(bandeira) {
            this.carregando = true

            var data = {
                id_negociacao_comercial: this.id_negociacao_comercial,
                mesFinal: this.mesFinal,
                termino: this.termino,
                bandeira: bandeira,
                lote: this.acordoRenovar.lote,
            }

            var response = await this.$http().post(
                '/negociacaoComercial/renovarAcordo',
                {
                    data: data,
                }
            )

            this.$emit('filtrar')

            if (response.data.sucesso) {
                this.$emit('renovar', response.data)

                this.carregando = false
                this.dialogRenovar = false

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg

                setTimeout(() => {
                    this.alerta = false
                    this.carregando = false
                    this.dialogRenovar = false
                }, 1000)
            } else {
                this.dialogoErro.open = true
                this.dialogoErro.label = 'CONTINUAR'
                this.dialogoErro.area = 'renovar'
                this.dialogoErro.data = response.data
                this.listaErro = response.data.erros
                this.carregando = false
                this.paginationErro.page = 1
            }
        },
        fecharErros(area) {
            this.dialogoErro.open = false
            this.carregando = false
            this.dialogRenovar = false

            switch (area) {
                case 'renovar':
                    this.$emit('renovar', this.dialogoErro.data)
                    break
            }
        },
        cancelar(item) {
            this.justificativa = null

            this.dialogCancelar = true

            this.id_negociacao_comercial = item.id_negociacao_comercial
            this.dialogTitulo =
                'Cancelar Acordo (' + item.id_negociacao_comercial + ')'
            this.dialogTexto = 'Deseja realmente cancelar o acordo?'
        },
        async cancelarAcordo() {
            this.carregando = true
            const justificativa = this.justificativasCancelamento.find(
                (item) => item.value == this.justificativa
            )
            const data = {
                id_negociacao_comercial: this.id_negociacao_comercial,
                id_justificativa: this.justificativa,
                justificativa: justificativa.text,
            }

            var response = await this.$http().post(
                '/negociacaoComercial/cancelarAcordo',
                {
                    data: data,
                }
            )

            this.$emit('filtrar', false)

            if (response.data.sucesso) {
                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg

                setTimeout(() => {
                    this.alerta = false
                    this.carregando = false
                    this.dialogCancelar = false
                }, 1000)
            } else {
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
                this.carregando = false
            }
        },
        liberar(acordos, investimento) {
            this.dialogLiberar = true
            this.acordosALiberar = acordos
            this.investimento = investimento.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        },
        async liberarAcordo() {
            this.$emit('liberarAcordo', this.acordosALiberar)

            this.dialogLiberar = false
        },
    },
}
</script>

<style lang="scss">
.tabela {
    overflow: hidden;
    color: #808080 !important;
}
.tabela .v-data-table__wrapper {
    border-radius: 10px 10px 0 0;
    border: 1px solid #eaeaea !important;
}
.resumo {
    .resumo-title {
        font-size: 1.1rem;
        background: var(--v-primary-base);
        text-align: center;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 8px 8px 0 0;
    }
    .resumo-conteudo {
        display: grid;
        grid-template-columns: 1fr 20px 1fr 20px 1fr;
        grid-gap: 15px;
        align-items: center;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        font-weight: 500;
        font-size: 0.8rem !important;
        color: #808080;
        p {
            margin: 0;
        }
        .resumo-item {
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            border-radius: 8px;
            background: #eaeaea;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .titulo {
                font-weight: bold;
                color: var(--v-primary-base);
            }
        }
    }
}
</style>