<template>
    <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
            <div class="indicador" v-bind="attrs" v-on="on" >
                <div class="indicador-icon">
                    <div class="icon-content">
                        <v-icon class="icon" size="32">{{ icone }}</v-icon>
                    </div>
                </div>
                <div class="indicador-text">
                    <span>{{ titulo }}: </span>
                </div>
                <div class="indicador-value">
                    <span>{{ valor || 0 }}</span>
                </div>
            </div>
        </template>
        <span>Clique para exportar listagem </span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'Indicador',
    props: ['titulo', 'valor', 'icone', 'color', 'click'],
    methods: {
        formatarValor(valor) {
            return Number(valor).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        },
    },
}
</script>

<style scoped>
.indicador.active::before {
    background: var(--v-primary-base);
}
.indicador.active {
    background: var(--v-primary-base);
    color: #fff;
}
.indicador {
    margin: 0.4rem 0.2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    background: #f5f5f5;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;
    cursor: pointer;
}

.indicador.active .indicador-icon {
    background: white;
}
.indicador.active .icon {
    color: var(--v-primary-base);
}
.indicador .icon {
    color: white;
}
.indicador::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #eaeaea;
    transition: all 0.4s;
}
.indicador:hover::before {
    background: var(--v-primary-base);
}
.indicador:hover {
    filter: grayscale(0);
}
.indicador-icon {
    background-color: var(--v-primary-base);
    border-radius: 50%;
    display: flex;
    position: relative;
    padding: 6px;
    /* margin-top: 6px; */
    margin: 4px;
    box-shadow: 0 0 10px #0002;
}
.indicador-text {
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 1rem;
}
.indicador-separador {
    width: 25px;
    height: 1px;
    background: #e0e0e0;
    margin-top: 5px;
}
.indicador-value {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 5px;
    color: var(--v-primary-base);
}
.indicador.active .indicador-value {
    color: white;
}
.icone-download {
    justify-self: center;
    align-self: center;
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 1rem;
}
</style>