<template>

  <v-form
    v-on:submit.prevent="filtrar"
    ref="formFiltro"
    class="filtros-acordos"
  >
    <v-divider class="mt-2 mb-3"></v-divider>
    <v-row>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <DataInput
          label="Período Inicial"
          v-model="periodoInicial"
          :rules="regra.periodoInicial"
          :change="periodoInicialChange"
          :first-day-of-week="1"

          required
        ></DataInput>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <DataInput
          label="Período Final"
          v-model="periodoFinal"
          :rules="regra.periodoFinal"
          :change="periodoFinalChange"
          :first-day-of-week="1"

          required
        ></DataInput>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-select
          label="Semana"
          outlined
          dense
          class="input-cinza"
          v-model="semana"
          :items="listaSemanas"
          :menu-props="menuProps"
          multiple
        ></v-select>
      </v-col>

      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-select
          label="Bandeira"
          v-model="id_bandeira"
          :items="lista.bandeira"
          :menu-props="menuProps"
          outlined
          dense
          class="input-cinza"
          multiple
        >
        </v-select>
      </v-col>
    </v-row>
    <v-expansion-panels flat v-model="mostrarFiltros" accordion>
      <v-expansion-panel>
        <v-expansion-panel-content eager class="p-0 m-0">
          <v-row>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Ativação"
                class="input-cinza"
                outlined
                dense
                :items="lista.ativacoes"
                v-model="id_ativacao"
                :menu-props="menuProps"
                multiple
              >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="selecionarTodasAtivacoes"
                >
                    <v-list-item-action>
                        <v-icon>
                            {{
                                id_ativacao
                                    .length >= (lista.ativacoes ? lista.ativacoes.length : 0)
                                    ? "mdi-close-box"
                                    : id_ativacao
                                          .length === 0
                                    ? "mdi-checkbox-blank-outline"
                                    : "mdi-minus-box"
                            }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{
                                id_ativacao
                                    .length >= (lista.ativacoes ? lista.ativacoes.length : 0)
                                    ? "Desmarcar"
                                    : "Selecionar"
                            }}
                           todas as ativações
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
                  <div
                    v-if="id_ativacao.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="id_ativacao.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ id_ativacao.length }} selecionados</span
                  >
                </template>
            </v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Device"
                outlined
                dense
                class="input-cinza"
                v-model="device"
                :items="lista.device"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>

            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Tipo/Área"
                class="input-cinza"
                outlined
                dense
                :items="lista.areas"
                v-model="id_area"
                :menu-props="menuProps"
                multiple
              >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="selecionarTodosTiposAreas"
                >
                    <v-list-item-action>
                        <v-icon>
                            {{
                                id_area
                                    .length >= (lista.areas ? lista.areas.length : 0)
                                    ? "mdi-close-box"
                                    : id_area
                                          .length === 0
                                    ? "mdi-checkbox-blank-outline"
                                    : "mdi-minus-box"
                            }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{
                                id_area
                                    .length >= (lista.areas ? lista.areas.length : 0)
                                    ? "Desmarcar"
                                    : "Selecionar"
                            }}
                           todos os Tipos/Áreas
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
                  <div
                    v-if="id_area.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="id_area.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ id_area.length }} selecionados</span
                  >
                </template>
            </v-autocomplete>
              
            </v-col>

            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Fornecedor"
                v-model="id_fornecedor"
                :items="lista.fornecedor"
                multiple
                :menu-props="menuProps"
                outlined
                dense
                class="input-cinza"
              >
              
                <template v-slot:selection="{ item, index }">
                  <div
                    v-if="id_fornecedor.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="id_fornecedor.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ id_fornecedor.length }} selecionados</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Categoria"
                class="input-cinza"
                outlined
                dense
                :items="lista.categorias"
                v-model="id_categoria"
                :menu-props="menuProps"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <div
                    v-if="id_categoria.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="id_categoria.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ id_categoria.length }} selecionados</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
    
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Macro Fornecedores"
                outlined
                dense
                class="input-cinza"
                v-model="id_macrofornecedor"
                :items="lista.macrofornecedor"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <!-- <v-text-field
                label="Ação"
                outlined
                dense
                class="input-cinza"
                v-model="filtro.acao"
              ></v-text-field> -->
              
              <v-autocomplete 
                    v-model="acao"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    color="white"
                    hide-no-data
                    hide-selected
                    item-text="Description"
                    item-value="API"
                    label="Ação"
                    class="input-cinza"
                    required
                    return-object
                    outlined
                    dense
                ></v-autocomplete>
              
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Validação"
                class="input-cinza"
                outlined
                dense
                :items="lista.status_planejamento"
                v-model="id_status_planejamento"
                :menu-props="menuProps"
                multiple
              >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="selecionarTodasValidacoes"
                >
                    <v-list-item-action>
                        <v-icon>
                            {{
                                id_status_planejamento
                                    .length >= (lista.status_planejamento ? lista.status_planejamento.length : 0)
                                    ? "mdi-close-box"
                                    : id_status_planejamento
                                          .length === 0
                                    ? "mdi-checkbox-blank-outline"
                                    : "mdi-minus-box"
                            }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{
                                id_status_planejamento
                                    .length >= (lista.status_planejamento ? lista.status_planejamento.length : 0)
                                    ? "Desmarcar"
                                    : "Selecionar"
                            }}
                           todas Validações
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
                  <div
                    v-if="id_status_planejamento.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="id_status_planejamento.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ id_status_planejamento.length }} selecionados</span
                  >
                </template>
            </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-text-field
                label="Número planejamento"
                outlined
                dense
                class="input-cinza"
                v-model="id_planejamento"
              ></v-text-field>
            </v-col>
          </v-row>  
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider class="mt-0 mb-3"></v-divider>
    <v-row>
      <v-col cols="3" class="px-2 ml-auto">
        <div class="new-button d-flex align-center" text>
          <span @click="expandirFiltros" style="cursor: pointer"
            >Personalizar Filtros</span
          >
          <v-btn
            style="background: #e6e6e6; width: 20px; height: 20px"
            fab
            icon
            class="ml-3"
            @click="expandirFiltros"
            x-small
          >
            <v-icon>{{
              mostrarFiltros == 0 ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="9" class="ml-auto" align="end">
        <v-btn class="ml-2 new-button" color="yellow" dark @click="limpar()">
          <v-icon left>mdi-filter-off</v-icon> Limpar Filtros
        </v-btn>
        <v-btn class="ml-5 new-button" color="primary" type="submit">
          <v-icon left>mdi-filter</v-icon> Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :value="carregando">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
import useFiltros from "@/hooks/useFiltros";
import Data from "@/plugins/datas";
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'planejamento_ecommerce_filtro',
  setup() {
    const { isLoading, isError, isFetching, lista, error, refetch } =
      useFiltros("/planejamentoEcommerce/listaCampos");

    return { isLoading, isError, isFetching, lista, error, refetch };
  },
  // inject: ["listaCampos"],
  data() {
    return {
      carregando: false,
      mostrarFiltros: null,
      listaSemanas: [
        { value: 1, text: "Semana 1" },
        { value: 2, text: "Semana 2" },
        { value: 3, text: "Semana 3" },
        { value: 4, text: "Semana 4" },
        { value: 5, text: "Semana 5" },
      ],
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      filtro: {
        periodoInicial: this.$moment().startOf("month").format("YYYY-MM-DD"),
        periodoFinal: this.$moment().endOf("month").format("YYYY-MM-DD"),
      },
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      search: null,
      regra: {
        periodoInicial: [(v) => !!v || "O campo período inicial é obrigatório"],
        periodoFinal: [
          (v) => !!v || "O campo período final é obrigatório",
          (v) =>
            (!!v && this.periodoFinal >= this.periodoInicial) ||
            "O período final deve ser igual ou posterior ao período inicial",
        ],
      },
    };
  },
  // mounted() {
  //   this.iniciaFiltro();
  // },
  computed: {
    // fields () {
    //     if (!this.acao) return []
    //     return Object.keys(this.acao).map(key => {
    //     return {
    //         key,
    //         value: this.acao[key] || 'n/a',
    //     }
    //     })
    // },
    items () {
      console.log('this.entries',this.entries)
        const itemsx = this.entries.map(entry => {
        const Description = entry.length > this.descriptionLimit
            ? entry.slice(0, this.descriptionLimit) + '...'
            : entry
        // return Object.assign({}, entry, { Description })
        return Description
        })
        console.log(itemsx)
        return itemsx
    },
    ...mapFields('planejamento_ecommerce',[
        'filtro.periodoInicial',
        'filtro.periodoFinal',
        'filtro.tipoPeriodo',
        'filtro.id_bandeira',
        'filtro.id_planejamento',
        'filtro.semana',
        'filtro.id_ativacao',
        'filtro.device',
        'filtro.id_area',
        'filtro.id_fornecedor',
        'filtro.id_categoria',
        'filtro.plano',
        'filtro.id_status_planejamento',
        'filtro.ativacao',
        'filtro.id_macrofornecedor',
        'filtro.acao'
    ])
  },
  async created() {
    await new Promise((resolve) => setTimeout(resolve, 100));
    // this.iniciaFiltro();
    // this.carregando = false;
  },
  
  // computed: {
  //   // fields () {
  //   //     if (!this.acao) return []
  //   //     return Object.keys(this.acao).map(key => {
  //   //     return {
  //   //         key,
  //   //         value: this.acao[key] || 'n/a',
  //   //     }
  //   //     })
  //   // },
  //   items () {
  //     // console.log('this.entries',this.entries)
  //       return this.entries.map(entry => {
  //       const Description = entry.length > this.descriptionLimit
  //           ? entry.slice(0, this.descriptionLimit) + '...'
  //           : entry
  //       // return Object.assign({}, entry, { Description })
  //       return Description
  //       })
  //       // console.log(itemsx)
  //       // return itemsx
  //   },
    
  // },
  watch: {
    async search (val) {
        if (!val) {
          this.acao = ''
        }
        if (!val || val.length < 4) {
          this.entries = []
          return
        }  
        if (this.isLoading) return
        this.isLoading = true
        var response = await this.$http().post("/planejamentoEcommerce/acoesparaautocomplete", {
            data: {acao: val,limite:30},
        });
  // watch: {
  //   async search (val) {
  //       // Items have already been loaded
  //       if (val.length < 4) return
  //       // Items have already been requested
  //       if (this.isLoading) return
  //       this.isLoading = true
        
  //       // Lazily load input items
  //       var response = await this.$http().post("/planejamentoEcommerce/acoesparaautocomplete", {
  //           data: {acao: val,limite:30},
  //       });
  //       // console.log(response)
        this.count = response.data.length
  //       let items = [
  //           val,
  //           ...response.data.map(item => item.acao)
  //       ]
        // console.log(items)
        this.entries = [
            val,
            ...response.data.map(item => item.acao)
        ]
        // this.value.produtosServidor = response.data
        // fetch('https://api.publicapis.org/entries')
        // .then(res => res.json())
        // .then(res => {
        //     const { count, entries } = res
        //     this.count = count
        //     this.entries = entries
        // })
        // .catch(err => {
        //     console.log(err)
        // })
        // .finally(() => (this.isLoading = false))
        this.isLoading = false
    },
  },
  // methods: {
  //   iniciaFiltro() {
  //     const { periodoInicial, periodoFinal } = this.periodoMes();
  //     this.filtro = {
  //       periodoInicial: periodoInicial.format("YYYY-MM-DD"),
  //       periodoFinal: periodoFinal.format("YYYY-MM-DD"),
  //       tipoPeriodo: -1,
  //       id_bandeira: [],
  //       id_planejamento: null,
  //       semana: [],
  //       id_ativacao: [],
  //       device: [],
  //       id_area: [],
  //       id_fornecedor: [],
  //       id_categoria: [],
  //       plano: [],
  //       id_status_planejamento: [],
  //       ativacao: [],
  //     };
  //   },
  // },
  methods: {
    // iniciaFiltro() {
    //   const { periodoInicial, periodoFinal } = this.periodoMes();
    //   this.filtro = {
    //     periodoInicial: periodoInicial.format("YYYY-MM-DD"),
    //     periodoFinal: periodoFinal.format("YYYY-MM-DD"),
    //     tipoPeriodo: -1,
    //     id_bandeira: [],
    //     id_planejamento: null,
    //     semana: [],
    //     id_ativacao: [],
    //     device: [],
    //     id_area: [],
    //     id_fornecedor: [],
    //     id_categoria: [],
    //     plano: [],
    //     id_status_planejamento: [],
    //     ativacao: [],
    //   };
    // },
    limpar() {
      // this.iniciaFiltro();

      this.$emit("limpar");
    },
    filtrar() {
      this.$emit("filtrar");
    },
    validate() {
      return this.$refs.formFiltro.validate();
    },
    expandirFiltros() {
      this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
    },
    periodoInicialChange(valor) {
      if (this.periodoFinal < valor) {
        this.periodoFinal = valor;
      }
    },
    periodoFinalChange(valor) {
      if (this.periodoInicial > valor) {
        this.periodoInicial = valor;
      }
    },
    periodoMes() {
      const semanas = Data.calculaSemanas(this.$moment());
      const periodoInicial = semanas[0].inicio;
      const periodoFinal = semanas[semanas.length - 1].fim;
      return { periodoInicial, periodoFinal };
    },
    selecionarTodasAtivacoes() {
      if (this.id_ativacao.length === this.lista.ativacoes.length) {
        this.id_ativacao = [];
      } else {
        this.id_ativacao = this.lista.ativacoes.map((a) => a.value);
      }
    },
    selecionarTodosTiposAreas() {
      if (this.id_area.length === this.lista.areas.length) {
        this.id_area = [];
      } else {
        this.id_area = this.lista.areas.map((a) => a.value);
      }
    },
    selecionarTodasValidacoes() {
      if (this.id_status_planejamento.length === this.lista.status_planejamento.length) {
        this.id_status_planejamento = [];
      } else {
        this.id_status_planejamento = this.lista.status_planejamento.map((a) => a.value);
      }
    },
    resetValidation() {
      try {
        this.$refs.formFiltro.resetValidation();
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>
