<template>
    <v-container fluid>
        <v-card class="px-5 py-3  br-15 w-100" elevation="0">
        <v-row >           
            <v-col class="flex-grow-1">
            <v-toolbar-title class="page-title">Usuários</v-toolbar-title>
            </v-col>
     
            <v-col cols="8" align="end" class="d-flex">   
                <v-text-field
                    v-model="busca"
                    append-icon="mdi-magnify"
                    class="input-button input-cinza"
                    label="Pesquisar"
                    dense
                    outlined
                    hide-details
                >
                    <template>
                        <v-icon slot="append" color="white">mdi-magnify</v-icon>
                    </template></v-text-field>
                <v-btn
                    class="ml-4 new-button"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus-thick</v-icon> Criar usuário
                </v-btn>
                <v-btn
                    class="ml-4 new-button"
                    color="primary"
                    @click="regional()"
                >
                    <v-icon left>mdi-plus-thick</v-icon> Regional
                </v-btn>
                
                <v-menu offset-y min-width="180">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="ml-4 new-button"
                        color="primary"  
                        v-bind="attrs"
                        v-on="on"
                   
                    >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
                    </v-btn>
                  </template>
                  <v-list dense nav>
                    <v-list-item link @click="exportar()">
                      <v-list-item-icon class="pl-2 mr-0">
                        <v-icon color="grey lighten-1">mdi-microsoft-excel</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Exportar Usuários</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="exportar('filiais')">
                      <v-list-item-icon class="pl-2 mr-0">
                        <v-icon color="grey lighten-1">mdi-microsoft-excel</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Vínculos entre filiais</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </v-col>               
        </v-row >
         
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :search="busca"
                :footer-props="tableConfig"
                :options.sync="pagination"
                multi-sort
                class="elevation-0 tabela mt-2"
            >   
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"   
                        @click="editarFornecedor(item)"
                        title="Fornecedores"
                    >
                        mdi-factory
                    </v-icon>
                    <v-icon
                        small
                        class="ml-4 mr-2"
                        color="primary"   
                        @click="editarFiliais(item)"
                        title="Filiais"
                    >
                    mdi-storefront-outline
                    </v-icon>
                    <v-icon
                        small
                        class="ml-4 mr-2"
                        color="primary"   
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="ml-4 mr-2"
                        color="primary"   
                        @click="dialogoRedefinir=true; usuario={...item};"
                        title="Redefinir Senha"
                    >
                        mdi-lock-reset
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog 
            content-class="br-15" 
            v-model="dialogo"
            width="600"     
            scrollable       
        >  
            <v-card>
             
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                       {{ usuario.id_usuario? 'Editar' : 'Cadastrar' }} Usuário
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogo = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text  class="pa-5"> 
                    <v-form ref="form" v-model="validaForm"> 
                        <v-row no-gutters class="mt-4">                                
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Id Usuário"
                                    v-model="usuario.id_usuario"
                                    class="input-cinza"
                                ></v-text-field> 
                            </v-col>                                          
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Nome"
                                    v-model="usuario.nome"
                                    :rules="regra.nome"
                                    class="input-cinza"
                                    required
                                ></v-text-field> 
                            </v-col>                                          
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Login"
                                    v-model="usuario.login"
                                    :rules="regra.login"
                                    class="input-cinza"
                                    required
                                ></v-text-field> 
                            </v-col>                                                                                      
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="E-mail"
                                    v-model="usuario.email"
                                    class="input-cinza"
                                ></v-text-field> 
                            </v-col>                                                
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-select
                                    label="Cargo"
                                    :items="lista.cargo"
                                    v-model="usuario.id_cargo"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>                                                      
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-select
                                    label="Status"
                                    :items="lista.ativo"
                                    v-model="usuario.ativo"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    outlined
                                    dense 
                                    :rules="regra.ativo"
                                >
                                </v-select>
                            </v-col>         
                        </v-row>  
                    </v-form>
                </v-card-text>
                <v-card-actions  class="pa-5">
                    <v-spacer></v-spacer>
       
                    <v-btn
                        color="primary ml-4 new-button"
                        class="pr-4"
                        :disabled="!validaForm"
                        @click="salvar()"
                    >
                    <v-icon class="mr-3">mdi-check-circle</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            scrollable
            v-model="dialogoFornecedor"
            width="800"        
            content-class="br-15"    
        >  
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        Fornecedores por usuário
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoFornecedor = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <fornecedores :usuario="usuario"/>
                
            </v-card>
        </v-dialog>
        <v-dialog
            scrollable
            v-model="dialogoFiliaisUsuario"
            width="800"        
            content-class="br-15"    
        >  
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        Filiais por usuário
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoFiliaisUsuario = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <filiais :usuario="usuario"/>
                
            </v-card>
        </v-dialog>
        <v-dialog
            scrollable
            v-model="dialogoRegional"
            width="800"        
            content-class="br-15"    
        >  
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        Filiais por regionais
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoFiliaisUsuario = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <filiaisRegionais @fecharRegional="fecharRegional" />
                
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogoRedefinir"
            width="600"            
            content-class="br-15"
            scrollable

        >  
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        Redefinir Senha
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoRedefinir = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text  class="pa-5"> 
                    Você realmente deseja redefinir a senha para o padrão inicial? 
                </v-card-text>
                <v-card-actions  class="pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4 new-button"
                        @click="dialogoRedefinir = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4 ml-4 new-button "
                        @click="redefinirSenha()"
                    >
                        <v-icon left>mdi-lock-reset</v-icon> Redefinir Senha
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>
import Fornecedores from './Fornecedores.vue'
import Filiais from './Filiais.vue'
import FiliaisRegionais from './FiliaisRegionais.vue'
export default {
    data() {
        return {
            headers: [
                { text: 'ID Usuário', value: 'id_usuario', align: 'center', class: 'th-separador'},
                { text: 'Nome', value: 'nome', align: 'center', class: 'th-separador'},
                { text: 'Login', value: 'login', align: 'center', class: 'th-separador'},
                { text: 'E-mail', value: 'email', align: 'center', class: 'th-separador'},
                { text: 'Cargo', value: 'cargo', align: 'center', class: 'th-separador'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center', class: 'th-separador'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [], 
            lista: {
                ativo: [
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 0},
                ],
                cargo: [],
            },
            usuario: {
                id_usuario: null,
                nome: null,
                login: null,
                email: null,
                cargo: null,
                ativo: null,
            },
            tableConfig: {
                itemsPerPageOptions: [5, 10, 15, 25, 50, 100],
                itemsPerPageText: "Linhas por páginas",
                ofText: "de",
                pageText: "{0}-{1} de {2}",
            },  
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },      
            pagination: {}, 
            carregando: false, 
            busca: null, 
            dialogo: false,
            dialogoRedefinir: false,
            dialogoFornecedor: false,
            dialogoFiliaisUsuario: false,
            dialogoRegional: false,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                nome: [v => !!v || 'O campo nome é obrigatório.'],
                login: [v => !!v || 'O campo login é obrigatório.'],
                ativo: [v => (v == 0 || v == 1) || 'O campo ativo é obrigatório.'],
            },  
        }
    },
    watch: {
    },
    async created() { 
        
        var response = await this.$http().post('/usuario/cargo', {}) 

        this.lista.cargo = response.data  
        
        this.filtrar()      
    },
    components: { Fornecedores, Filiais, FiliaisRegionais },
    methods: {
        regional () {
            this.dialogoRegional = true
        },
        async fecharRegional () {
            this.dialogoRegional = false
        },
        importarRegional () {

        },
        async filtrar() {

            this.carregando = true

            this.pagination.page = 1

            var data = {}

            var response = await this.$http().post('/usuario/lista', {data: data})   
            
            this.dados = response.data           

            this.carregando = false
        },
        async exportar(tipo= 'usuarios') {
           
            this.carregando = true
            
            var data = {
                tipo: tipo
            }

            var response = await this.$http().post('/usuario/exportar', {data: data})   
          
            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        novo() {
           
           
            try {
                this.$refs.form.reset()
            } catch (error) {
                console.log(error)
                
            }
            this.usuario = {
                id_usuario: null,
                nome: null,
                login: null,
                email: null,
                cargo: null,
                ativo: 1,
            }
            this.dialogo = true          
        }, 
        editar(item) {
           
            this.usuario = {...item}
            
            this.dialogo = true          
        }, 
        editarFornecedor(item) {
           
           this.usuario = {...item}
           
           this.dialogoFornecedor = true          
       }, 
       editarFiliais(item) {
           
           this.usuario = {...item}
           
           this.dialogoFiliaisUsuario= true          
       }, 
        async salvar() {
            
            this.carregando = true

            var data = this.usuario

            var response = await this.$http().post('/usuario/salvar', {data: data})   
            
            if(response.data.sucesso){

                this.filtrar()

                this.dialogo = false  
            }
            else{

                this.carregando = false
                
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }, 
        async redefinirSenha() {
            
            this.carregando = true

            var data = this.usuario

            var response = await this.$http().post('/usuario/redefinirSenha', {data: data})   
            
            this.carregando = false
            this.dialogoRedefinir = false
            
            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg  
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }, 
    },
}
</script>