import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import { http } from '@/config/http'

export default {
    namespaced: true, 
    state: {
        usuario: null,
        // servidor: {
        //     periodoInicial: moment().startOf("month").format("YYYY-MM-DD"),
        //     periodoFinal: moment().endOf("month").format("YYYY-MM-DD"),
        // },
        filtro: {
            periodoInicial: moment().startOf("month").format("YYYY-MM-DD"),
            periodoFinal: moment().endOf("month").format("YYYY-MM-DD"),
            tipoPeriodo: -1,
            id_bandeira: [],
            id_planejamento: null,
            semana: [],
            id_ativacao: [],
            device: [],
            id_area: [],
            id_fornecedor: [],
            id_categoria: [],
            plano: [],
            id_status_planejamento: [],
            ativacao: [],
            id_macrofornecedor: [],
            acao: '',
        },
    },
    actions: {
        async getInitialData(context) {
            const response = await http().post('/planejamentoEcommerce/getcache')
            if (response.status == 200 && response.data.payload) {
                const payload = response.data.payload
                context.commit('setFiltro',payload)    
            }
        },
        async saveCache(context) {
            const response = await http().post('/planejamentoEcommerce/setcache',{
                payload:{...context.state.filtro}
            })
            if (response.status == 200 && response.data.payload) {
                const { payload } = response.data.payload
                context.commit('setFiltro',payload)    
            }

        },
        limparFiltro(context) {
            context.commit('setClear')
        },
        setAtivacao(context,payload) {
            context.commit('setAtivacao',payload)
        },
        setStatus(context,payload) {
            context.commit('setStatus',payload)
        }
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        setFiltro(state,payload) {
            state.filtro.periodoInicial = payload.periodoInicial
            state.filtro.periodoFinal = payload.periodoFinal
            state.filtro.tipoPeriodo = payload.tipoPeriodo
            state.filtro.id_bandeira = payload.id_bandeira
            state.filtro.id_planejamento = payload.id_planejamento
            state.filtro.semana = payload.semana
            state.filtro.id_ativacao = payload.id_ativacao
            state.filtro.device = payload.device
            state.filtro.id_area = payload.id_area
            state.filtro.id_fornecedor = payload.id_fornecedor
            state.filtro.id_categoria = payload.id_categoria
            state.filtro.plano = payload.plano
            state.filtro.id_status_planejamento = payload.id_status_planejamento
            state.filtro.ativacao = payload.ativacao
            state.filtro.id_macrofornecedor = payload.id_macrofornecedor
            state.filtro.acao = payload.acao
        },
        setAtivacao(state,payload) {
            if (state.filtro.ativacao.includes(payload)) {
                state.filtro.ativacao = []    
            } else {
                state.filtro.ativacao = [payload]
            }
        },
        setStatus(state,payload) {
            if(!state.filtro.id_status_planejamento) {
                state.filtro.id_status_planejamento = []
            }


            if (state.filtro.id_status_planejamento.includes(payload)) {
                state.filtro.id_status_planejamento = []    
            } else {
                state.filtro.id_status_planejamento = [payload]
            }
        },
        setClear (state) {
            state.filtro.periodoInicial = moment().startOf("month").format("YYYY-MM-DD")
            state.filtro.periodoFinal = moment().endOf("month").format("YYYY-MM-DD")
            state.filtro.tipoPeriodo = -1
            state.filtro.id_bandeira = []
            state.filtro.id_planejamento = null
            state.filtro.semana = []
            state.filtro.id_ativacao = []
            state.filtro.device = []
            state.filtro.id_area = []
            state.filtro.id_fornecedor = []
            state.filtro.id_categoria = []
            state.filtro.plano = []
            state.filtro.id_status_planejamento = []
            state.filtro.ativacao = []
            state.filtro.id_macrofornecedor = []
            state.filtro.acao = ''
        }
    }    
}    